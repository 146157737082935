import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { handleError } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  CheckboxGroupController,
  ResourcesList,
  EditorController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { SHORTREADDETAIL } from "Constants/QueriesKeys";
import ImageController from "Components/FormControls/Controllers/ImageController";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { HookFormDefaultValue } from "Components/HookFormDefaultValue/HookFormDefaultValue";
import { toast } from "react-toastify";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  description: Yup.string().required("required"),
  title: Yup.string().required("required"),
  viewers: Yup.array().of(Yup.string()).min(1).required("selectOne").nullable(),
  image: Yup.array(),
});

const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function CreateUpdateShortRead() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { shortReadId } = useParams();

  const shortReadQuery = useQuery([SHORTREADDETAIL, { shortReadId }], () =>
    http.get(`/shortread/${shortReadId}`, {}).then(({ data }) => data),
  );
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: HookFormDefaultValue(shortReadQuery?.data?.data)
  });


  const { control, handleSubmit, setError, reset, errors } = form;


  const selectedFor = useWatch({
    name: "viewers",
    control
  })?.find((item) => item === "Student") ? true : false

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    //convert to form data
    
    let formData = new FormData();

    for (var i = 0; i < values?.image?.length; i++) {
      formData.append("image", values?.image[i]);
    }
    const data = {
      title: values?.title,
      description: values?.description.replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""),
      isParent: values?.viewers?.find((item) => item === "Parent") ? true : false,
      isStudent: values?.viewers?.find((item) => item === "Student") ? true : false,
      isTeacher: values?.viewers?.find((item) => item === "Teacher") ? true : false,
      isBoy: values?.gender?.find((item) => item === "Boy") ? true : false,
      isGirl: values?.gender?.find((item) => item === "Girl") ? true : false,
      isElementarySchool: values?.school?.find((item) => item === "Elementary") ? true : false,
      isMiddleSchool: values?.school?.find((item) => item === "Middle") ? true : false,
      isHighSchool: values?.school?.find((item) => item === "High") ? true : false
    }


    if (shortReadId) {
      try {
        if (values?.image?.length) {
          await http.post(`/file/upload`, formData)
            .then(async (res) => {
              data['headerImage'] = res?.data?.data[0]?.filename
              try {
                await http.put(`/shortread/update/${shortReadId}`, data)
                  .then(async (res) => {
                    history.push('/admin/short-read');
                  });
                reset({});
              } catch (error) {
                handleError(error, { setError });
              }
            });
        } else {
          data['headerImage'] = shortReadQuery?.data?.data?.headerImage
          try {
            await http.put(`/shortread/update/${shortReadId}`, data)
              .then(async (res) => {
                history.push('/admin/short-read');
                toast.success(t('toast.success.updatedSuccessfully'))
              });
            reset({});
          } catch (error) {
            handleError(error, { setError });
          }
        }
        reset({});
      } catch (error) {
        handleError(error, { setError });
      }
    } else {
      if (values?.image?.length) {
        try {
          await http.post(`/file/upload`, formData)
            .then(async (res) => {
              data['headerImage'] = res?.data?.data[0]?.filename
              try {
                await http.post(`/shortread/create`, data)
                  .then(async (res) => {
                    history.push('/admin/short-read');
                    toast.success(t('toast.success.addedSuccessfully'))
                  });
                reset({});
              } catch (error) {
                handleError(error, { setError });
              }
            });
          reset({});
        } catch (error) {
          handleError(error, { setError });
        }
      } else {
        toast.error(t('toast.errors.headerImage'))
      }
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.shortRead.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{shortReadId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={6} lg={5} xl={4}>
                    <InputController
                      size="small"
                      control={control}
                      name="title"
                      label={t("admin.shortRead.create.title")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CheckboxGroupController
                  label={t('admin.shortRead.create.viewers')}
                  control={control}
                  name="viewers"
                  row
                  options={[{ label: t('admin.shortRead.create.parent'), value: 'Parent' }, { label: t('admin.shortRead.create.teacher'), value: 'Teacher' }, { label: t('admin.shortRead.create.student'), value: 'Student' }]}
                />
              </Grid>
              <Grid item xs={12}>
                {selectedFor ?
                  <>
                    <CheckboxGroupController
                      label={t('admin.video.gender')}
                      control={control}
                      name="gender"
                      row
                      options={[{ label: t('admin.video.boy'), value: 'Boy' }, { label: t('admin.video.girl'), value: 'Girl' }]}
                    />
                    <CheckboxGroupController
                      label={t('admin.video.school')}
                      control={control}
                      name="school"
                      row
                      options={[{ label: t('admin.video.elementary'), value: "Elementary" }, { label: t('admin.video.middle'), value: "Middle" }, { label: t('admin.video.high'), value: "High" }]}
                    />
                  </>
                  : null}
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={8} lg={8}>
                    <EditorController
                      control={control}
                      name="description"
                      label={t('admin.shortRead.create.description')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item>
                    <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.video.image")}</h6>
                    <ImageController
                      name="image"
                      filesLimit={5}
                      acceptedFiles
                      form={form}
                      errors={errors}
                    />
                  </Grid>
                  {shortReadId ?
                    <Grid item xs={12}>
                      <img src={`${process.env.REACT_APP_FILE_BASE_URL}/${shortReadQuery?.data?.data?.headerImage}`} alt="headerImg" style={{ width: '80px' }} />
                    </Grid>
                    : null}
                </Grid>
              </Grid>
            </Grid>
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                {t("common.submit")}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}

export default CreateUpdateShortRead;
