import React from "react";
import { useForm } from "react-hook-form";
import { handleError } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  ResourcesList,
  RadioGroupController,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { SHORTREADDETAIL } from "Constants/QueriesKeys";
import { HookFormDefaultValue } from "Components/HookFormDefaultValue/HookFormDefaultValue";
import { useLocation } from "react-router-dom";


const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  name: Yup.string().required("required"),
  email: Yup.string().required("required"),
  // role: Yup.string().required("required"),
});


const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function CreateUpdateUser() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { userId } = useParams();


  const form = useForm({
    resolver: yupResolver(schema)
  });


  const { control, handleSubmit, setError, reset } = form;

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    const data = {
      name: values?.name,
      // role: values?.role,
      email: values.email,
    }

    if (values.mobileNo) {
      data["mobileNo"] = values?.mobileNo
    }
    if(userId){
    try {
      await http.put(`/admin/update/${userId}`, data)
        .then(async (res) => {
          history.push('/admin/users');
          toast.success("Updated Successfully")
        });
      reset({});
    } catch (error) {
      handleError(error, { setError });
    }
  }else{
    try {
      await http.post(`/admin/create`, data)
        .then(async (res) => {
          history.push('/admin/users');
          toast.success("Added Successfully")
        });
      reset({});
    } catch (error) {
      handleError(error, { setError });
    }
  }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.users.users')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{userId ? "Update User" : "Create New User"}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5 col-span-4 w-6/12"
          >
            <InputController
              size="small"
              control={control}
              name="name"
              defaultValue={userId ? location?.state?.details?.name : ""}
              label={t("admin.users.create.name")}
            />
            <InputController
              size="small"
              control={control}
              disabled={userId ? true : false}
              name="email"
              defaultValue={userId ? location?.state?.details?.email : ""}
              label={t("admin.users.create.email")}
            />
            <InputController
              size="small"
              control={control}
              name="mobileNo"
              defaultValue={userId ? location?.state?.details?.mobileNo : ""}
              label={t("admin.users.create.mobileNo")}
            />

            {/* <RadioGroupController
              label={t('admin.users.create.role')}
              control={control}
              name="role"
              row
              options={[{ label: t('admin.users.create.role1'), value: 1 }, { label: t('admin.users.create.role2'), value: 2 }]}
            /> */}
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
               {userId ? "Update" : "Submit"}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}

export default CreateUpdateUser;