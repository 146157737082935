import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AppLayout from 'Layouts/AppLayout';
import SignInPage from 'Pages/Auth/SignInPage';
import { NavLinks } from 'Constants/NavLinks';
import VideoPage from 'Pages/Video/VideoPage';
import CreateUpdateVideo from 'Pages/Video/CreateUpdateVideo';
import MagzainePage from 'Pages/Magzine/MagazinePage';
import CreateUpdateMagzine from 'Pages/Magzine/CreateUpdateMagzine';
import VideoDetailPage from 'Pages/Video/VideoDetailPage';
import MagazineDetailPage from 'Pages/Magzine/MagazineDetailPage';
import ShortReadPage from 'Pages/ShortRead/ShortReadPage';
import ShortReadDetailPage from 'Pages/ShortRead/ShortReadDetailPage';
import CreateUpdateShortRead from 'Pages/ShortRead/CreateUpdateShortRead';
import SeriesPage from 'Pages/Series/SeriesPage';
import SeriesDetailPage from 'Pages/Series/SeriesDetailPage';
import CreateUpdateSeries from 'Pages/Series/CreateUpdateSeries';
import UserPage from 'Pages/Users/UserPage';
import CreateUpdateUser from 'Pages/Users/CreateUpdateUser';
import Profile from 'Pages/Profile/Profile';

export default function App() {
    let { path } = useRouteMatch();

    return (
        <AppLayout links={NavLinks}>
            <Switch>
                <Route exact path={`${path}/sign-in`} component={SignInPage} />
                <Route exact path={`${path}`} component={VideoPage} />

                <Route exact path={`${path}/video`} component={VideoPage} />
                <Route exact path={`${path}/video/details/:videoId`} component={VideoDetailPage} />
                <Route exact path={`${path}/video/edit/:videoId`} component={CreateUpdateVideo} />
                <Route exact path={`${path}/video/create`} component={CreateUpdateVideo} />

                <Route exact path={`${path}/series`} component={SeriesPage} />
                <Route exact path={`${path}/series/details/:seriesId`} component={SeriesDetailPage} />
                <Route exact path={`${path}/series/edit/:seriesId`} component={CreateUpdateSeries} />
                <Route exact path={`${path}/series/create`} component={CreateUpdateSeries} />

                <Route exact path={`${path}/users`} component={UserPage} />
                <Route exact path={`${path}/users/create`} component={CreateUpdateUser} />
                <Route exact path={`${path}/user/edit/:userId`} component={CreateUpdateUser} />

                <Route exact path={`${path}/short-read`} component={ShortReadPage} />
                <Route exact path={`${path}/short-read/details/:shortReadId`} component={ShortReadDetailPage} />
                <Route exact path={`${path}/short-read/edit/:shortReadId`} component={CreateUpdateShortRead} />
                <Route exact path={`${path}/short-read/create`} component={CreateUpdateShortRead} />

                <Route exact path={`${path}/magazine`} component={MagzainePage} />
                <Route exact path={`${path}/magazine/create`} component={CreateUpdateMagzine} />
                <Route exact path={`${path}/magazine/details/:magazineId`} component={MagazineDetailPage} />
                <Route exact path={`${path}/magazine/edit/:magazineId`} component={CreateUpdateMagzine} />

                <Route exact path={`${path}/profile`} component={Profile} />
            </Switch>
        </AppLayout>
    );
}
