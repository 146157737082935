import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, IconButton, InputAdornment, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Btn, InputController, setProgress } from 'Components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { http } from 'Utils/Http/Http';
import { LocalStorage } from "Utils/LocalStorage";


const storage = new LocalStorage();


const CHANGE_LINK_BTN = 'CHANGE_LINK_BTN';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    maxWidth: "500px",
    borderRadius: "20px",
    bgcolor: 'background.paper',
    // borderRadius:"20px",
    boxShadow: 24,
    p: 4,
};

const useStyle = makeStyles((theme) => ({
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    title: {
        fontSize: "23px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "500"
    },
    btn_submit: {
        backgroundColor: "#03a65a !important",
        fontSize: "12px",
        color: "#fff",
        '&:hover': {
            color: '#0863a3',
            backgroundColor: "rgba(241, 163, 36, 1) !important",
        }
    },
    btn_cancel: {
        backgroundColor: "rgba(0, 186, 241, 1) !important",
        fontSize: "12px",
        color: "#000",
        '&:hover': {
            color: '#fff',
            backgroundColor: "#0863a3 !important",
        }
    }
}));


const schema = yup.object().shape({
    password: yup.string().required(),
    newPassword: yup.string().required(),
    confirmNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'Password and Confirm Password do not match.'),
});


const PasswordChangeModal = ({ open, handleClose }) => {
    const classes = useStyle();
    const { t } = useTranslation();

    const [oldPasswordType, setOldPasswordType] = useState('password')
    const [passwordType, setPasswordType] = useState('password')
    const [confirmType, setConfirmType] = useState('password')

    const form = useForm({
        resolver: yupResolver(schema),
    });


    const { control, handleSubmit, setError, reset, errors } = form;


    const togglePasswordType = (val) => {
        if (val === 'old') {
            if (oldPasswordType === 'password') {
                setOldPasswordType('text')
            } else if (oldPasswordType === 'text') {
                setOldPasswordType('password')
            }
        } else if (val === 'password') {
            if (passwordType === 'password') {
                setPasswordType('text')
            } else if (passwordType === 'text') {
                setPasswordType('password')
            }
        } else if (val === 'confirm') {
            if (confirmType === 'password') {
                setConfirmType('text')
            } else if (confirmType === 'text') {
                setConfirmType('password')
            }
        }
    }

    const handleForgotPassword = async (values) => {
        const data = {
            oldPassword: values.password,
            newPassword: values.newPassword
        }
        setProgress(CHANGE_LINK_BTN, true);
        try {
            await http.post(`/admin/change_password`,
                { ...data },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                handleClose()
                toast.success("password changed successfully")
                reset({
                    oldPassword: "",
                    newPassword: ""
                })
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
            })
        } catch(res) {
            toast.error(res?.response?.data?.message)
        }
        setProgress(CHANGE_LINK_BTN, false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            <h3 className={classes.title}>Change Password</h3>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                    <form className="space-y-4" onSubmit={handleSubmit(handleForgotPassword)}>
                        <Grid container style={{ marginTop: "10px" }} spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={oldPasswordType}
                                    name="password"
                                    label="old Password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('old')} style={{ cursor: 'pointer' }}>
                                                    {oldPasswordType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={passwordType}
                                    name="newPassword"
                                    label="New Password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                                                    {passwordType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={confirmType}
                                    name="confirmNewPassword"
                                    label="Confirm New Password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('confirm')} style={{ cursor: 'pointer' }}>
                                                    {confirmType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: '10px' }} justifyContent="center" alignItems="center">
                            <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                                <Btn type="submit" name={CHANGE_LINK_BTN} className={classes.btn_submit}>
                                    Update
                                </Btn>
                            </Grid>
                            {/* <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                                <Btn className={classes.btn_cancel} >
                                    {t('changePassword.cancel')}
                                </Btn>
                            </Grid> */}
                        </Grid>
                    </form>
                </Box>
            </Fade>
        </Modal>
    )
}

export default PasswordChangeModal
