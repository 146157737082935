import React, { useState } from 'react'
import { Avatar, Button, Divider, Grid, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import yup from 'Utils/Yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputController } from 'Components';
import { http } from 'Utils/Http/Http';
import { toast } from 'react-toastify';
import styles from './student.module.css';
import { LocalStorage } from 'Utils/LocalStorage';

export const FETCHPROFILEDATA = 'FETCHPROFILEDATA'

const storage = new LocalStorage();

const Profile = () => {

    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().required(t("student.index.fullNameError")),
        mobileNo: yup.string().required(t("validation.mobileNo")),
        // email: yup.string().email().required(t("student.index.emailError")),
        // oldPassword: yup.string().required(t("student.index.passwordError")),
        // newPassword: yup.string().required(t("student.index.passwordError")),
        // confirmNewPassword: yup.string().required(t("validation.required")).oneOf([yup.ref('newPassword')], t("validation.confirmPasswordMatch")),
        // schoolName: yup.string().required(t("validation.schoolName")),
    });

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const handleFormSubmit = (data) => {
        console.log("data : ", data)
        http.post(`/admin/update_profile`,
            {
                name: data?.name,
                // schoolName: data?.schoolName,
                // password : data?.oldPassword,
                mobileNo: data?.mobileNo,
                // newPassword : data?.newPassword
            }
        ).then((response) => response?.data?.data ? toast.success(t("student.register.updateAccountSuccess")) : toast.error(t("student.register.updateAccountError")));
        reset({
            name: null,
            mobileNo: null
        });
    }



    return (
    
            <form
                onSubmit={handleSubmit(handleFormSubmit)}
                className="space-y-3 py-5 col-span-4 w-6/12"
            >
                <InputController
                    size="small"
                    control={control}
                    name="name"
                    defaultValue={storage?.get("user") ? storage?.get("user")?.name : ""}
                    label={t("admin.users.create.name")}
                />
                {/* <InputController
                    size="small"
                    control={control}
                    name="email"
                    label={t("admin.users.create.email")}
                /> */}
                <InputController
                    size="small"
                    control={control}
                    name="mobileNo"
                    defaultValue={storage?.get("user") ? storage?.get("user")?.mobileNo : ""}
                    label={t("admin.users.create.mobileNo")}
                />

                {/* <RadioGroupController
  label={t('admin.users.create.role')}
  control={control}
  name="role"
  row
  options={[{ label: t('admin.users.create.role1'), value: 1 }, { label: t('admin.users.create.role2'), value: 2 }]}
/> */}
                <div className="flex pt-3">
                    <Button type="submit" className={styles.btn_send} >Update</Button>
                </div>
            </form>
    )
}

export default Profile