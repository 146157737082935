import React, { useState } from 'react'
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { LocalStorage } from 'Utils/LocalStorage';
import { useTranslation } from 'react-i18next';

const storage = new LocalStorage();



const useStyle = makeStyles((theme) => ({
    username: {
        fontSize: "18px",
        color: "rgb(3, 166, 90)",
        textTransform: "capitalize",
    },
    menuItem: {
        color: "rgba(14, 109, 167, 1)",
        '&:hover': {
            color: 'rgba(14, 109, 167, 1)',
            backgroundColor: "rgba(14, 109, 167,0.3)"
        }
    }
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const ProfileButton = ({ handlePasswordModal, setLogoutModal, setPasswordModal }) => {
    const classes = useStyle();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElPassword, setAnchorElPassword] = useState(null);
    const { t } = useTranslation()
    const open = Boolean(anchorEl);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePasswordClose = () => {
        setAnchorElPassword(null);
    };

    const handleLogout = () => {
        setLogoutModal(true)
        setAnchorEl(null);
    }
    const openPasswordModal = () => {
        if (storage.get("user")?.schoolName) {
            history.push("/si/student")
        }else{
            history.push("/si/teacher")
        }
        handleClose()
        // handlePasswordModal()
    }

    const openChangePasswordModal = () => {
        setPasswordModal(true);
        setAnchorElPassword(null);
    }

    console.log(" storage : ",storage.get("user"))

    return (
        <React.Fragment>
           <Grid container direction="row" justifyContent="end" alignItems="baseline" textAlign="center" >
                <Grid item>
                    <Tooltip title="Account settings">
                        <IconButton
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <Avatar sx={{ width: 40, height: 40 }} style={{ textTransform: "capitalize" }}>{(storage.get("user")?.name[0])}</Avatar>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <h1 className={classes.username}>{storage.get("user")?.name}</h1>
                </Grid>
            </Grid>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {storage.get("user") ?
                    <>
                        <MenuItem onClick={() => history.push("/admin/profile")} disableRipple className={classes.menuItem}>
                        <EditIcon style={{ color: "rgba(14, 109, 167, 1)" }} />
                            Update Profile
                        </MenuItem>
                        <MenuItem onClick={openChangePasswordModal} disableRipple className={classes.menuItem}>
                        <EditIcon style={{ color: "rgba(14, 109, 167, 1)" }} />
                        Change Password
                        </MenuItem>
                    </>
                    : ""
                }
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleLogout} disableRipple className={classes.menuItem}>
                    <Logout style={{ color: "rgba(14, 109, 167, 1)" }} />
                    Logout
                </MenuItem>
            </StyledMenu>
        </React.Fragment>
    )
}

export default ProfileButton
