import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { USERLIST } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { handleError, http } from 'Utils/Http/Http';
import { ConfirmationDialog, DataGrid, IconBtnBase, ResourcesList } from 'Components';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { toast } from 'react-toastify';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { makeStyles } from '@mui/styles';

const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};



const useStyles = makeStyles((theme) => ({
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    }
}))



// Component goes here
const UserPage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const perPage = 10;
    const [userList, setUserList] = useState()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteUserDetails, setDeleteUserDetails] = useState()
    const [paginator, setPaginator] = useState()
    const userListRender = useQuery([USERLIST, { page, perPage }], () =>
        http.post(`/admin/list`, {
            query: {},
            options: {
                select: [
                ],
                page: page,
                paginate: perPage
            },
            isCountOnly: false
        }).then(({ data }) => { return (data, setUserList(data?.data?.data), setPaginator(data?.data?.paginator)) }),
    );

    const columns = [
        {
            field: 'name',
            headerName: t('admin.users.create.name'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: t('admin.users.create.email'),
            minWidth: 320,
            editable: false,
        },
        {
            field: 'mobileNo',
            headerName: t('admin.users.create.mobileNo'),
            minWidth: 180,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <span>{row?.row?.mobileNo ? row?.row?.mobileNo : "-"}</span>
                    </div>
                );
            },
        },
        {
            field: `role`,
            headerName: t('admin.users.create.role'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase
                        className={classes.btnClose}
                            onClick={() => {
                                handleUserDetail(row?.row);
                            }}
                            size="large">
                            <DeleteOutlineOutlinedIcon />
                        </IconBtnBase>
                        <IconBtnBase className={classes.btnEdit}  onClick={() => history.push({pathname : `/admin/user/edit/${row?.row?.id}`,
                    state : { details : row?.row} 
                    })} ><BorderColorOutlinedIcon /> </IconBtnBase>
                    </div>
                );
            },
        },
    ];

    
    
    const handleUserDetail = (userId) => {
        setOpenDeleteModal(true)
        setDeleteUserDetails(userId)
    };

    const handleSoftDelete = async () => {
        try {
            await http.put(`/admin/softDelete/${deleteUserDetails?.id}`, {})
                .then(async (res) => {
                    setOpenDeleteModal(false)
                    toast.success(t('toast.success.deletedSuccessfully'))
                    userListRender.refetch();
                });
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <ResourcesList
            headerProps={{
                title: t('admin.users.users'),
                EndSide: (
                    <div className='flex'>
                        <IconBtnBase
                            onClick={() => {
                                history.push(`${path}/create`);
                            }}
                            size="large">
                            <AddIcon />
                        </IconBtnBase>
                        <IconBtnBase
                            onClick={() => {
                                userListRender.refetch();
                            }}
                            size="large">
                            <RefreshIcon />
                        </IconBtnBase>

                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <Box sx={styles.tableSize}>
                        <Paper>
                            {userList ?
                                <DataGrid
                                    pagination
                                    rowCount={paginator?.itemCount}
                                    paginationMode="server"
                                    onPageChange={(newPage) => setPage(newPage + 1)}
                                    loading={userListRender?.isLoading}
                                    rows={userList}
                                    columns={columns}
                                    pageSize={perPage}
                                />
                                : null}
                        </Paper>
                    </Box>
                    <ConfirmationDialog isOpen={openDeleteModal} title="Delete" text={`Are you sure you want to delete ${deleteUserDetails?.name} from user?`} onReject={() => setOpenDeleteModal(false)} onResolve={() => handleSoftDelete(deleteUserDetails?.id)} />
                </div>
            }
        />
    );
};

export default UserPage;
