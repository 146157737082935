// export const FUNDS = 'FUNDS';
// export const ACCOUNTS = 'ACCOUNTS';
// export const ACCOUNTDETAIL = 'ACCOUNTDETAIL';
export const VIDEODETAIL='VIDEODETAIL'
export const VIDEOLIST='VIDEOLIST'
export const MAGAZINEDETAIL='MAGAZINEDETAIL'
export const MAGAZINELIST='MAGAZINELIST'
export const SHORTREADDETAIL='SHORTREADDETAIL'
export const SHORTREADLIST='SHORTREADLIST'
export const USERDETAIL='USERDETAIL'
export const USERLIST='USERLIST'
