import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { VIDEODETAIL } from 'Constants/QueriesKeys';
import { handleError, http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ResourcesList } from 'Components';
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import { SelectedGender, SelectedSchool } from 'Components/HookFormDefaultValue/HookFormDefaultValue';
import { toast } from 'react-toastify';




const styles = {
    tableSize: {
        minHeight: 'calc(100vh - 250px)',
        width: '100%',
    },
    h5Text: {
        textTransform: 'capitalize',
        color: 'red',
    }
};

const useStyles = makeStyles((theme) => ({
    h5Text: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,1)'
    },
    h3Text: {
        textTransform: 'capitalize',
        fontSize: '24px',
        color: 'rgb(3, 166, 90)',
        fontWeight: '500',
    },
    pText: {
        textTransform: 'capitalize',
        fontSize: '18px',
        color: 'rgba(0,0,0,0.8)'
    },
    btnClose: {
        color: 'rgba(255,0,0,0.5)',
        '&:hover': {
            color: 'rgba(255,0,0,0.7)'
        }
    },
    btnEdit: {
        color: 'rgba(3, 166, 90,0.5)',
        '&:hover': {
            color: 'rgba(3, 166, 90,0.7)'
        }
    }
}))

// Component goes here
const VideoDetailPage = () => {
    const spacing = 4
    const classes = useStyles();
    const history = useHistory();
    const { videoId } = useParams();
    const { t } = useTranslation();

    const [openDeleteModal, setOpenDeleteModal] = useState(false)


    const videoDetailsQuery = useQuery([VIDEODETAIL, { videoId }], () =>
        http.get(`/video/${videoId}`, {}).then(({ data }) => data),
    );

    const handleSoftDelete = async () => {
        try {
            await http.put(`/video/softDelete/${videoId}`, {})
                .then(async (res) => {
                    toast.success(t('toast.success.deletedSuccessfully'))
                    history.push('/admin/video');
                });
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <ResourcesList
                    BeforeHeader={
                        <Breadcrumbs className="mb-4">
                            <Link component={NavLink} to="/admin/video">
                                {t('common.video')}
                            </Link>
                            <Typography color="textPrimary">#{videoId}</Typography>
                        </Breadcrumbs>
                    }
                    Section={
                        <div className="mt-8">
                            <Box sx={styles.tableSize}>
                                <Paper>
                                    <Card variant="outlined" style={{ padding: '20px' }}>
                                        <Grid container justifyContent='space-between'>
                                            <Grid item>
                                                <h3 className={classes.h3Text}>{t("admin.videoDetail.videoDetails")}</h3>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item>
                                                        <IconButton className={classes.btnEdit} onClick={() => history.push(`/admin/video/edit/${videoId}`)} ><BorderColorOutlinedIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton className={classes.btnClose} onClick={() => setOpenDeleteModal(true)} ><DeleteOutlineOutlinedIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider style={{ margin: '10px 0' }} />
                                        <Grid container spacing={spacing}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={spacing}>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                                <h5 className={classes.h5Text}>{t("admin.videoDetail.title")}:</h5>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <p className={classes.pText}>{videoDetailsQuery?.data?.data?.title}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                                <h5 className={classes.h5Text}>{t('admin.video.gender')} :</h5>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <p className={classes.pText}>
                                                                {SelectedGender(videoDetailsQuery?.data?.data)?.map((item, index) => <span key={index}>{item==="Boy"?t('admin.video.boy'):item==="Girl"?t('admin.video.girl'):null}{SelectedGender(videoDetailsQuery?.data?.data)?.length > index + 1 ? ", " : null}</span>)}
                                                                </p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                                <h5 className={classes.h5Text}>{t('admin.video.school')} :</h5>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <p className={classes.pText}>
                                                                {SelectedSchool(videoDetailsQuery?.data?.data)?.map((item, index) => <span key={index}>{item==="Elementary"?t('admin.video.elementary'):item==="Middle"?t('admin.video.middle'):item==="High"?t('admin.video.high'):null}{SelectedSchool(videoDetailsQuery?.data?.data)?.length > index + 1 ? ", " : null}</span>)}
                                                                </p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} style={{ minHeight: '300px' }}>
                                                <ReactPlayer width="100%" height="100%" url={videoDetailsQuery?.data?.data?.youtubeLink} controls={true} />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Box>
                            <ConfirmationDialog isOpen={openDeleteModal} title="Delete" text="Are you sure you want to delete this video?" onReject={() => setOpenDeleteModal(false)} onResolve={handleSoftDelete} />
                        </div>
                    }
                />
            </Grid>
        </>
    );
};

export default VideoDetailPage;
