import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { handleError } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  CheckboxGroupController,
  ResourcesList,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { MAGAZINEDETAIL } from "Constants/QueriesKeys";
import ImageController from "Components/FormControls/Controllers/ImageController";
import ErrorDialog from "Components/Dialogs/DialogToast/ErrorDialog"
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { HookFormDefaultValue } from "Components/HookFormDefaultValue/HookFormDefaultValue";
import FileUpload from "Components/Image/FileUpload";
import { toast } from "react-toastify";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  gender: Yup.array().of(Yup.string()).min(1).required("selectOne").nullable(),
  school: Yup.array().of(Yup.string()).min(1).required("selectOne").nullable(),
  title: Yup.string().required("required"),
  image: Yup.array(),
  fileUrl: Yup.array(),
});


const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function CreateUpdateMagzine() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { magazineId } = useParams();
  const [enabled, setEnabled] = useState(true)
  const [fileSelected, setFileSelected] = useState()
  const [prevFileName, setPrevFileName] = useState()


  const magazineDetailsQuery = useQuery([MAGAZINEDETAIL, { magazineId }], () =>
    http.get(`/magazine/${magazineId}`, {}).then(({ data }) => { if (data?.data) form.reset(HookFormDefaultValue(data?.data)); setEnabled(false); return data; })
    , {
      enabled: magazineId !== undefined ? enabled ? true : false : false,
    });
  const form = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setPrevFileName(magazineDetailsQuery?.data?.data?.fileUrl)
  }, [magazineDetailsQuery])

  const { control, handleSubmit, setError, reset, errors } = form;
  const [isLoading, setIsLoading] = useState(true);

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    //convert to form data

    let formData = new FormData();
    for (var i = 0; i < values?.image?.length; i++) {
      formData.append("image", values?.image[i]);
    }
    const data = {
      title: values?.title,
      isBoy: values?.gender?.find((item) => item === "Boy") ? true : false,
      isGirl: values?.gender?.find((item) => item === "Girl") ? true : false,
      isElementarySchool: values?.school?.find((item) => item === "Elementary") ? true : false,
      isMiddleSchool: values?.school?.find((item) => item === "Middle") ? true : false,
      isHighSchool: values?.school?.find((item) => item === "High") ? true : false
    }

    if (magazineId) {
      try {
        if (fileSelected) {
          data['fileUrl'] = fileSelected
        } else {
          data['fileUrl'] = magazineDetailsQuery?.data?.data?.fileUrl
        }
        if (values?.image?.length) {
          await http.post(`/file/upload`, formData)
            .then(async (res) => {
              data['headerImage'] = res?.data?.data[0]?.filename
            });
        } else {
          data['headerImage'] = magazineDetailsQuery?.data?.data?.headerImage
        }
        try {
          await http.put(`/magazine/update/${magazineId}`, data)
            .then(async (res) => {
              history.push('/admin/magazine');
              toast.success(t('toast.success.updatedSuccessfully'))
            });
          reset({});
        } catch (error) {
          handleError(error, { setError });
        }
        reset({});
      } catch (error) {
        handleError(error, { setError });
      }
    } else {
      data['fileUrl'] = fileSelected
      if (values?.image?.length) {
        try {
          await http.post(`/file/upload`, formData)
            .then(async (res) => {
              data['headerImage'] = res?.data?.data[0]?.filename
              if (fileSelected) {
                try {
                  await http.post(`/magazine/create`, data)
                    .then(async (res) => {
                      history.push('/admin/magazine');
                      toast.success(t('toast.success.addedSuccessfully'))
                    });
                  reset({});
                } catch (error) {
                  handleError(error, { setError });
                }
              } else {
                toast.error(t('toast.errors.fileSelect'))
              }
            });
        } catch (error) {
          handleError(error, { setError });
        }
      } else {
        toast.error(t('toast.errors.headerImage'))
      }
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.magazine.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{magazineId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5 col-span-4 w-6/12"
          >
            <InputController
              size="small"
              control={control}
              name="title"
              label={t("admin.magazine.create.title")}
            />
            <h6 className="labelForm">{t("admin.magazine.create.fileUrl")}</h6>
            <FileUpload setIsLoading={setIsLoading} prevFileName={prevFileName} setSelectedFile={setFileSelected} accept=".pdf, .doc" name='fileUrl' />

            <CheckboxGroupController
              label={t('admin.video.gender')}
              control={control}
              name="gender"
              row
              options={[{ label: t('admin.video.boy'), value: 'Boy' }, { label: t('admin.video.girl'), value: 'Girl' }]}
            />
            <CheckboxGroupController
              label={t('admin.video.school')}
              control={control}
              name="school"
              row
              options={[{ label: t('admin.video.elementary'), value: "Elementary" }, { label: t('admin.video.middle'), value: "Middle" }, { label: t('admin.video.high'), value: "High" }]}
            />
            <Grid container spacing={3}>
              <Grid item>
                <h6 className="labelForm" style={{ marginBottom: '15px' }}>{t("admin.video.image")}</h6>
                <ImageController
                  name="image"
                  filesLimit={5}
                  acceptedFiles
                  form={form}
                  errors={errors}
                />
              </Grid>
              {magazineId ?
                <Grid item xs={12}>
                  <img src={`${process.env.REACT_APP_FILE_BASE_URL}/${magazineDetailsQuery?.data?.data?.headerImage}`} alt="headerImg" style={{ width: '80px' }} />
                </Grid>
                : null}
            </Grid>
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" progress={isLoading} name={CREATE_PAYOUT_BTN}>
                {t("common.submit")}
              </Btn>
            </div>
          </form>
        </Card>
      }
    />
  );
}

export default CreateUpdateMagzine;