import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { handleError } from "Utils/Http/Http";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  Btn,
  InputController,
  setProgress,
  CheckboxGroupController,
  ResourcesList,
} from "Components";
import Yup from "Utils/Yup";
import { useHistory, useParams } from "react-router";
import { http } from "Utils/Http/Http";
import Card from "@mui/material/Card";
import { useQuery } from "react-query";
import { VIDEODETAIL } from "Constants/QueriesKeys";
import ImageController from "Components/FormControls/Controllers/ImageController";
import ErrorDialog from "Components/Dialogs/DialogToast/ErrorDialog"
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { HookFormDefaultValue } from "Components/HookFormDefaultValue/HookFormDefaultValue";
import { toast } from "react-toastify";

const CREATE_PAYOUT_BTN = "CREATE_PAYOUT_BTN";

const schema = Yup.object().shape({
  youtubeLink: Yup.string().required("required"),
  gender: Yup.array().of(Yup.string()).min(1).required("selectOne").nullable(),
  school: Yup.array().of(Yup.string()).min(1).required("selectOne").nullable(),
  title: Yup.string().required("required"),
  image: Yup.array(),
});


const useStyles = makeStyles((theme) => ({
  h3Text: {
    textTransform: 'capitalize',
    fontSize: '24px',
    color: 'rgb(3, 166, 90)',
    fontWeight: '500',
  }
}))

function CreateUpdateVideo() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { videoId } = useParams();
  const [errorOpen, setErrorOpen] = useState(false)

  const videoDetailsQuery = useQuery([VIDEODETAIL, { videoId }], () =>
    http.get(`/video/${videoId}`, {}).then(({ data }) => data),
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: HookFormDefaultValue(videoDetailsQuery?.data?.data)
  });


  const { control, handleSubmit, setError, reset, errors } = form;

  const handleFormSubmission = async (values) => {
    setProgress(CREATE_PAYOUT_BTN, true);
    //convert to form data

    let formData = new FormData();
    // formData.append("tenant_id", values.tenant_id);
    // formData.append("role", "ADMIN");
    // formData.append("title", values?.title);
    // formData.append("text", values?.text);
    for (var i = 0; i < values?.image?.length; i++) {
      formData.append("image", values?.image[i]);
    }
    const data = {
      title: values?.title,
      type: 1,//1 for video and 2 for series
      youtubeLink: values?.youtubeLink,
      isBoy: values?.gender?.find((item) => item === "Boy") ? true : false,
      isGirl: values?.gender?.find((item) => item === "Girl") ? true : false,
      isElementarySchool: values?.school?.find((item) => item === "Elementary") ? true : false,
      isMiddleSchool: values?.school?.find((item) => item === "Middle") ? true : false,
      isHighSchool: values?.school?.find((item) => item === "High") ? true : false,
    }

    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = values?.youtubeLink.match(regExp);
    if (match && match[2].length === 11) {
      if (videoId) {
        try {
          await http.put(`/video/update/${videoId}`, data)
            .then(async (res) => {
              history.push('/admin/video');
              toast.success(t('toast.success.updatedSuccessfully'))
            });
          reset({});
        } catch (error) {
          handleError(error, { setError });
        }
      } else {
        try {
          await http.post(`/video/create`, data)
            .then(async (res) => {
              history.push('/admin/video');
            });
          reset({});
        } catch (error) {
          handleError(error, { setError });
        }
      }
    } else {
      setErrorOpen(true)
      // Do anything for not being valid
    }
    setProgress(CREATE_PAYOUT_BTN, false);
  };

  return (
    <ResourcesList
      BeforeHeader={
        <>
          <Typography variant="h6" color="textPrimary">
            {t('admin.video.title')}
          </Typography>
        </>
      }
      Section={
        <Card variant="outlined" className="p-5">
          <Grid container justifyContent='space-between'>
            <Grid item>
              <h3 className={classes.h3Text}>{videoId ? t("common.updateDetails") : t("common.createNew")}</h3>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <IconButton onClick={() => history.goBack()} ><CloseIcon /></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0' }} />
          <form
            onSubmit={handleSubmit(handleFormSubmission)}
            className="space-y-3 py-5 col-span-4 w-6/12"
          >
            <InputController
              size="small"
              control={control}
              name="title"
              label={t("admin.video.create.title")}
            />
            <InputController
              size="small"
              control={control}
              name="youtubeLink"
              label={t("admin.video.youtubelink")}
            />
            <CheckboxGroupController
              label={t('admin.video.gender')}
              control={control}
              name="gender"
              row
              options={[{ label: t('admin.video.boy'), value: 'Boy' }, { label: t('admin.video.girl'), value: 'Girl' }]}
            />
            <CheckboxGroupController
              label={t('admin.video.school')}
              control={control}
              name="school"
              row
              options={[{ label: t('admin.video.elementary'), value: "Elementary" }, { label: t('admin.video.middle'), value: "Middle" }, { label: t('admin.video.high'), value: "High" }]}
            />
            <div className="flex pt-3">
              <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN}>
                {t("common.submit")}
              </Btn>
            </div>
          </form>
          <ErrorDialog isOpen={errorOpen} onReject={() => setErrorOpen(false)} title={t('admin.video.invalidLink')} message={t('admin.video.youtubeLinkInvalidMessage')} />
        </Card>
      }
    />
  );
}

export default CreateUpdateVideo;
