import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import GroupIcon from '@mui/icons-material/Group';

export const NavLinks = [
    { name: 'Videos', path: '/admin/video', current: true, Icon: OndemandVideoIcon },
    { name: 'Series', path: '/admin/series', current: true, Icon: VideoLibraryIcon },
    { name: 'Magazine', path: '/admin/magazine', current: true, Icon: ImportContactsIcon },
    { name: 'Short Read', path: '/admin/short-read', current: true, Icon: TextSnippetIcon },
    { name: 'Users', path: '/admin/users', current: true, Icon: GroupIcon },
];
